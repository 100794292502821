import React from "react";
// import ColumnImages from "./ColumnImages/ColumnImages";
// import ColumnDetails from "./ColumnDetails/ColumnDetails";
import "./TwoColumnStatic.scss";
// import ColumnSubLinks from "./ColumnSubLinks/ColumnSubLinks";
// import ColumnSubColumns from "./ColumnSubColumns/ColumnSubColumns";
// import BlockContent from "../../BlockContent/BlockContent";
// import ColumnSubStars from "./ColumnSubStars/ColumnSubStars";
// import ColumnSubTools from "./ColumnSubTools/ColumnSubTools";

const TwoColumnStatic = ({
    wrapperClasses,
    leftContentClasses,
    rightContentClasses,
    leftContent,
    rightContent,
}) => {
    // const colArray = [columnsData.columnOne, columnsData.columnTwo]
    // const colTypes = colArray.map(colData => colData.columnTypes[0] && colData.columnTypes[0]._type)

    // const ColumnComponent = ({ col }) => {
    //     switch (col._type) {
    //         case 'columnImages':
    //             return <ColumnImages columnImagesData={col}/>
    //         case 'columnDetails':
    //             return <ColumnDetails columnDetailsData={col}/>
    //         case 'subLinks':
    //             return <ColumnSubLinks columnSubLinksData={col}/>
    //         case 'subStars':
    //             return <ColumnSubStars columnSubStarsData={col}/>
    //         case 'subTools':
    //             return <ColumnSubTools columnSubToolsData={col}/>
    //         case 'subColumns':
    //             return (
    //                 <ColumnSubColumns subColumnsData={col}>
    //                     {col.titleWithAccent && <BlockContent blocks={col.titleWithAccent}/>}
    //                 </ColumnSubColumns>
    //             )
    //         default:
    //             return null
    //     }
    // }

    // const ColumnComponents = [columnsData.columnOne, columnsData.columnTwo].map((colData, key) =>
    //     colData ?
    //         <div className={'column-wrapper ' + (colData.columnTypes[0] && colData.columnTypes[0]._type)} key={key}>
    //             {colData.columnTypes[0] && colData.columnTypes[0]._type && <ColumnComponent col={colData.columnTypes[0]}/>}
    //         </div> : null
    // )

    // This component takes in html or component props for left and right content. You can also add additional classnames on the containers.

    return (
        <div className="two-column-static">
            <section className={`two-columns-wrapper ${wrapperClasses ? wrapperClasses : ""}`}>
                <div className={`column-wrapper ${leftContentClasses ? leftContentClasses : ""}`}>
                    {leftContent}
                </div>
                <div className={`column-wrapper ${rightContentClasses ? rightContentClasses : ""}`}>
                    {rightContent}
                </div>
            </section>
        </div>
    );
};

export default TwoColumnStatic;
