import React from "react";
import Layout from "../components/Layout/Layout";
import { StaticImage } from "gatsby-plugin-image";
import TwoColumnStatic from "../components/TwoColumnStatic/TwoColumnStatic";
import "../styles/base.scss";
import ColumnStatic from "../components/TwoColumns/Columns/ColumnStatic/ColumnStatic";

const PageNotFound = ({ errors, data, location }) => {
    const seoPageData = {
        title: "404 - Page Not Found",
        description: "404 - Page Not Found",
        altCanonical: location.href,
    };

    const cta = {
        text: "Take me home!",
        externalLink: false,
        href: "/",
        ariaLabel: "Take me home",
        additionalClasses: "",
    };

    const leftContent = (
        <ColumnStatic
            headingText={["That page has been ", <span className="accent">86'd</span>]}
            innerText={[<span className="text-grey text-xl">Can we get you anything else?</span>]}
            cta={cta}
            bottomContent={[
                <span className="text-grey text-lg">Error code: 404 Page Not Found</span>,
            ]}
        ></ColumnStatic>
    );
    const rightContent = (
        <div className="" style={{ position: "relative" }}>
            <div className="static-image-wrapper moon-bg">
                <StaticImage
                    src={"../images/404-hero.png"}
                    alt="Restaurant server taking an order from customers"
                    auto="format"
                    placeholder="blurred"
                />
            </div>
        </div>
    );

    return (
        <Layout seoData={seoPageData} hideForm>
            <TwoColumnStatic
                wrapperClasses=""
                LeftContentClasses=""
                rightContentClasses=""
                leftContent={leftContent}
                rightContent={rightContent}
            />
        </Layout>
    );
};

export default PageNotFound;
